import React, { useState } from 'react';
import Img from 'gatsby-image';
import { Box, Flex } from 'theme-ui';
import FsLightBox from 'fslightbox-react';
import PropTypes from 'prop-types';
import TextTruncate from 'react-text-truncate';
import { useResponsiveValue } from '@theme-ui/match-media';
import Text from '../Text';
import Stack from '../Stack';

function FishingPageLivingItem(props) {
  const {
    images = [],
    expanded,
    toggleExpanded,
    description,
    secondaryTitle,
    title,
    price,
  } = props;
  const [toggler, setToggler] = useState(false);
  const maxTruncLines = useResponsiveValue([2, 3, 5]);
  const profileImage = images?.[0];

  const readMore = (
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault();
        toggleExpanded();
      }}
    >
      Läs mer
    </a>
  );
  return (
    <>
      <FsLightBox toggler={toggler} type="image" sources={images} />
      <Flex
        sx={{
          cursor: 'pointer',
          bg: 'accent-3',
          textAlign: 'left',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          height: expanded ? '100%' : ['8rem', '12rem'],
          width: '100%',
        }}
      >
        <Box
          onClick={() => setToggler(!toggler)}
          sx={{
            bg: 'accent-4',
            width: ['8rem', '12rem'],
            height: '100%',
            flexShrink: 0,
          }}
        >
          {profileImage ? (
            <>
              <Img
                fluid={{
                  maxHeight: '10rem',
                  maxWidth: '10rem',
                  objectFit: 'cover',
                  aspectRatio: 1,
                  src: profileImage,
                  srcSet: '',
                  sizes: '',
                }}
              />
            </>
          ) : null}
        </Box>
        <Flex
          sx={{
            height: '100%',
            flexDirection: 'column',
            px: [2, 3],
            pt: [2, 3],
            pb: 2,
            flexGrow: 1,
          }}
        >
          <Stack space={1}>
            <Box>
              <Text.H5 as="p">{title}</Text.H5>
              <Text.P sx={{ fontSize: [0, 1], color: 'accent-5' }}>
                {secondaryTitle}
              </Text.P>
            </Box>

            <Box sx={{ flexGrow: 1 }}>
              <Text.P
                sx={{
                  whiteSpace: expanded ? 'pre-line' : null,
                  fontSize: 0,
                  position: 'relative',
                }}
              >
                {expanded ? (
                  description
                ) : (
                  <TextTruncate
                    line={maxTruncLines}
                    element="span"
                    truncateText="…"
                    text={description}
                    textTruncateChild={readMore}
                  />
                )}
              </Text.P>
            </Box>
          </Stack>
          <Flex
            sx={{
              width: '100%',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              flexGrow: 1,
              flexShrink: 0,
              px: [1, 3],
            }}
          >
            <Text.P as="p" sx={{ fontSize: 0, fontWeight: 'bold' }}>
              {price}
            </Text.P>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}

FishingPageLivingItem.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string.isRequired,
  secondaryTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
};

FishingPageLivingItem.defaultProps = {
  images: [],
};

export default FishingPageLivingItem;
